// refer to webpack.app.js - list in the same order
window.EmotionCore = require('@emotion/core');
window.EmotionStyled = require('@emotion/styled');
window.SSSForwardProp = require('@styled-system/should-forward-prop');
window.Axios = require('axios');
window.Classnames = require('classnames');

window.CloneDeep = require('clone');
window.EmotionTheming = require('emotion-theming');
window.FastDeepEqual = require('fast-deep-equal');
window.Noty = require('noty');
window.NProgress = require('nprogress');

window.ObjectPath = require('object-path');
window.ReactDOM = require('react-dom');
window.ReactRouterDOM = require('react-router-dom');
window.ReactRouter = require('react-router');
window.React = require('react');

window.StyledSystem = require('styled-system');
window.Tippy = require('@tippy.js/react');

const Reporter = require('stackdriver-errors-js');

if (/localhost/.test(window.location.host)) {
  class CCErrorBoundary extends window.React.Component {
    render() {
      return this.props.children;
    }
  }
  window.CCErrorBoundary = CCErrorBoundary;
} else {
  const errReporter = new Reporter();

  // api key is configured / restricted here
  // https://console.cloud.google.com/apis/credentials/key/04dd05ad-ce02-4176-a8a0-28db65fc112b?project=ccart-prod
  errReporter.start({
    key: 'AIzaSyASrlHYaIG4fxAjXEHjvh98Y6jBTgOKt_g',
    projectId: 'ccart-prod',
  });

  class CCErrorBoundary extends window.React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
    static getDerivedStateFromError() {
      return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
      console.log('log.error', error, errorInfo);
      const { service } = this.props;
      if (service) errReporter.serviceContext.service = service;
      errReporter.report(error);
    }
    render() {
      if (this.state.hasError) {
        return React.createElement(
          'div',
          {
            style: {
              color: '#ff5722',
              fontWeight: 'bold',
              fontSize: 14,
              fontFamily: 'monospace',
            },
          },
          'Something went wrong'
        );
      } else {
        return this.props.children;
      }
    }
  }
  window.CCErrorBoundary = CCErrorBoundary;
}
